const menuTrigger = document.querySelector(`.js-menu-trigger`);
const emptyHashes = [...document.querySelectorAll(`[href="#"]`)];

if (menuTrigger) {
  menuTrigger.addEventListener('click', () => {
    document.body.classList.toggle('js-menu-active');
  });
}

if (emptyHashes) {
  for (let index = 0; index < emptyHashes.length; index += 1) {
    emptyHashes[index].addEventListener('click', (e) => {
      e.preventDefault();
    });
  }
}
