const toggleItems = [...document.querySelectorAll('.js-toggle-action-card')];
const actionCard = document.querySelector('.c-action-card');

if (toggleItems) {
  toggleItems.forEach((element) => {
    element.addEventListener('click', () => {
      actionCard.classList.toggle('c-action-card--active');
    });
  });
}

/*
// Trigger the action card when user hits the bottom of the page

function handleIntersection(entries) {
  [...entries].forEach((entry) => {
    if (entry.isIntersecting) {
      setTimeout(() => {
        actionCard.classList.add('c-action-card--active');
      }, '2000');
    } else {
      setTimeout(() => {
        actionCard.classList.remove('c-action-card--active');
      }, '2000');
    }
  });
}

const target = document.querySelector('.c-footer');
const observer = new IntersectionObserver(handleIntersection);
observer.observe(target);
*/
