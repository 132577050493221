const carouselNav = [...document.querySelectorAll(`.js-carousel-trigger`)];

function carouselScroll(elem, x, y) {
  elem.scrollBy({
    top: x,
    left: y,
    behavior: `smooth`,
  });
}

if (carouselNav) {
  for (let index = 0; index < carouselNav.length; index += 1) {
    carouselNav[index].addEventListener('click', (e) => {
      if (e.target.dataset.direction === 'next') {
        carouselScroll(e.target.parentNode.previousSibling, 0, 316);
      } else {
        carouselScroll(e.target.parentNode.previousSibling, 0, -316);
      }
    });
  }
}
